import React, { useState, useEffect, useRef } from "react";
import { 
  Table, Button, Drawer, Form, Input, Select, message, Space, Tooltip, 
  Tag, Modal, Spin, Card, Descriptions, Divider, Empty, Typography, Skeleton
} from "antd";
import axios from "axios";
import {
  SearchOutlined, EditOutlined, DeleteOutlined, EyeOutlined, PlusOutlined,
  LoadingOutlined, CloseCircleOutlined, CheckCircleOutlined
} from "@ant-design/icons";
import constants from "../constants";

const { Option } = Select;
const { Title, Text } = Typography;

const Interview = () => {
  const [users, setUsers] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [deleteLoadingId, setDeleteLoadingId] = useState(null);
  const [previewLoading, setPreviewLoading] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      position: ["bottomCenter"],
    },
    filters: {},
  });
  const searchInput = useRef(null);
  const [form] = Form.useForm();

  // Fetch users with optional filtering
  const fetchUsers = async () => {
    setLoading(true);
    try {
      // Build query parameters from filters
      let query = [];
      Object.keys(tableParams.filters).forEach((k) => {
        if (tableParams.filters[k]?.length > 0) {
          query.push(`userDetails.${k}:sw=${tableParams.filters[k]}`);
        }
      });

      const res = await axios.get(`${constants.DOMAIN}/api/v1/eval/getAllInterviews?${query.join("&")}`);
      setUsers(res.data.data);
    } catch (error) {
      console.error("Failed to fetch users:", error);
      message.error("Failed to load interview data");
    } finally {
      setLoading(false);
    }
  };

  // Fetch user details for preview
  const fetchUserDetails = async (id) => {
    setPreviewLoading(true);
    try {
      const res = await axios.get(`${constants.DOMAIN}/api/v1/eval/getInterview/${id}`);
      setSelectedUser(res.data.data);
    } catch (error) {
      console.error("Failed to fetch user details:", error);
      message.error("Failed to load interview details");
    } finally {
      setPreviewLoading(false);
    }
  };

  // Fetch users when filters or pagination change
  useEffect(() => {
    fetchUsers();
  }, [tableParams.filters, tableParams.booleanFilters, tableParams.pagination.current, tableParams.pagination.pageSize]);

  // Update form fields when currentUser changes
  useEffect(() => {
    if (currentUser) {
      form.setFieldsValue({
        name: currentUser.userDetails?.name,
        email: currentUser.userDetails?.email,
        phone: currentUser.userDetails?.phone,
        role: currentUser.userDetails?.role,
      });
    } else {
      form.resetFields();
    }
  }, [currentUser, form]);

  // Open drawer for editing a user
  const handleEdit = (user) => {
    setCurrentUser(user);
    setIsDrawerOpen(true);
  };

  // Delete a user
  const handleDelete = async (id) => {
    setDeleteLoadingId(id);
    try {
      await axios.delete(`${constants.DOMAIN}/api/v1/eval/deleteInterview/${id}`);
      message.success("Interview record deleted successfully");
      fetchUsers();
    } catch (error) {
      console.error("Failed to delete user:", error);
      message.error("Failed to delete interview record");
    } finally {
      setDeleteLoadingId(null);
    }
  };

  // Open preview modal
  const handlePreview = async (user) => {
    await fetchUserDetails(user._id);
    setIsPreviewModalOpen(true);
  };

  // Submit form (create or update user)
  const handleFormSubmit = async (values) => {
    setFormLoading(true);
    try {
      if (currentUser) {
        // Update existing user
        // await axios.put(`${constants.DOMAIN}/api/v1/systemuser/updateUser/${currentUser._id}`, values);
        message.success("User updated successfully");
      } else {
        // Create new user
        await axios.post(`${constants.DOMAIN}/api/v1/eval/createInterview`, values);
        message.success("Interview created successfully");
      }

      setIsDrawerOpen(false);
      fetchUsers();
      form.resetFields();
      setCurrentUser(null);
    } catch (error) {
      console.error("Error:", error);
      message.error(error.response?.data?.message || "Failed to save user");
    } finally {
      setFormLoading(false);
    }
  };

  // Search functionality for columns
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            icon={<SearchOutlined />}
            size="small"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          >
            Search
          </Button>
          <Button size="small" onClick={() => handleReset(clearFilters, dataIndex)}>
            Reset
          </Button>
          <Button type="link" size="small" onClick={close}>
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  });

  // Handle search action - now properly preserves existing filters
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setTableParams({
      ...tableParams,
      pagination: { ...tableParams.pagination, current: 1 },
      filters: {
        ...tableParams.filters,
        [dataIndex]: selectedKeys,
      },
    });
  };

  // Reset specific search filter
  const handleReset = (clearFilters, dataIndex) => {
    clearFilters();
    
    // Create a new filters object without the cleared filter
    const newFilters = { ...tableParams.filters };
    delete newFilters[dataIndex];
    
    setTableParams({
      ...tableParams,
      pagination: { ...tableParams.pagination, current: 1 },
      filters: newFilters,
    });
  };

  // Handle boolean filters (attempted, processed)
  const handleBooleanFilter = (value, dataIndex) => {
    setTableParams({
      ...tableParams,
      pagination: { ...tableParams.pagination, current: 1 },
      booleanFilters: {
        ...(tableParams.booleanFilters || {}),
        [dataIndex]: value,
      },
    });
  };

  // Handle table change (pagination, filters)
  const handleTableChange = (pagination, filters, sorter) => {
    // Process boolean filters from the table
    const booleanFilters = {};
    
    if (filters.attempted && filters.attempted.length > 0) {
      booleanFilters.attempted = filters.attempted[0];
    }
    
    if (filters.processed && filters.processed.length > 0) {
      booleanFilters.processed = filters.processed[0];
    }
    
    setTableParams({
      pagination,
      filters: tableParams.filters, // Keep text search filters
      booleanFilters,
    });
  };

  // Format date for display
  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    const date = new Date(dateString);
    return date.toLocaleString();
  };

  // Render status tag with appropriate color
  const renderStatusTag = (status) => {
    if (!status) return <Tag color="default">No Status</Tag>;
    
    const statusColors = {
      EVALUATED: "green",
      PENDING: "orange",
      FAILED: "red",
      INPROGRESS: "blue"
    };
    
    return <Tag color={statusColors[status] || "default"}>{status}</Tag>;
  };

  return (
    <div style={{ padding: "24px" }}>
      <div style={{ marginBottom: "16px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Title level={4}>Interview Management</Title>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            setCurrentUser(null);
            setIsDrawerOpen(true);
          }}
        >
          Add Interview
        </Button>
      </div>

      {/* Main Table with loading state */}
      <Card>
        <Table
          dataSource={users}
          rowKey="_id"
          pagination={tableParams.pagination}
          loading={{
            spinning: loading,
            indicator: <LoadingOutlined style={{ fontSize: 24 }} spin />,
            tip: "Loading interview data..."
          }}
          onChange={handleTableChange}
          locale={{
            emptyText: <Empty description="No interview data found" />
          }}
          columns={[
            {
              title: "Name",
              dataIndex: "userDetails",
              ...getColumnSearchProps("name"),
              render: (_, record) => record?.userDetails?.name || "N/A",
            },
            {
              title: "Email",
              dataIndex: "userDetails",
              ...getColumnSearchProps("email"),
              render: (_, record) => record?.userDetails?.email || "N/A",
            },
            {
              title: "Phone",
              dataIndex: "userDetails",
              ...getColumnSearchProps("phone"),
              render: (_, record) => record?.userDetails?.phone || "N/A",
            },
            {
              title: "Interview Status",
              dataIndex: "interview",
              filters: [
                { text: 'EVALUATED', value: 'EVALUATED' },
                { text: 'PENDING', value: 'PENDING' },
                { text: 'FAILED', value: 'FAILED' },
                { text: 'INPROGRESS', value: 'INPROGRESS' },
              ],
              onFilter: (value, record) => record?.interview?.status === value,
              render: (_, record) => renderStatusTag(record?.interview?.status),
            },
            {
              title: "Attempted",
              dataIndex: "attempted",
              filters: [
                { text: 'Yes', value: true },
                { text: 'No', value: false },
              ],
              onFilter: (value, record) => record.attempted === value,
              render: (attempted) => (
                attempted ? 
                <Tag color="green" icon={<CheckCircleOutlined />}>Yes</Tag> : 
                <Tag color="red" icon={<CloseCircleOutlined />}>No</Tag>
              ),
            },
            {
              title: "Processed",
              dataIndex: "processed",
              filters: [
                { text: 'Yes', value: true },
                { text: 'No', value: false },
              ],
              onFilter: (value, record) => record.processed === value,
              render: (processed) => (
                processed ? 
                <Tag color="green" icon={<CheckCircleOutlined />}>Yes</Tag> : 
                <Tag color="red" icon={<CloseCircleOutlined />}>No</Tag>
              ),
            },
            {
                title: "Link",
                dataIndex: "link",
                render: (_, record) => (
                 <Button variant="link" onClick={()=>{
                    navigator.clipboard.writeText(`https://app.youvah.com/interview/${record._id}`)
                    .then(() => console.log("Text copied!"))
                    .catch(err => console.error("Failed to copy:", err));
                 }}>Copy Link</Button>
                ),
              },
            {
              title: "Actions",
              render: (_, record) => (
                <Space size="middle">
                  {/* <Tooltip title="Edit">
                    <Button 
                      icon={<EditOutlined />} 
                      onClick={() => handleEdit(record)} 
                    />
                  </Tooltip> */}
                  <Tooltip title="Delete">
                    <Button
                      danger
                      icon={<DeleteOutlined />}
                      loading={deleteLoadingId === record._id}
                      onClick={() => handleDelete(record._id)}
                    />
                  </Tooltip>
                  <Tooltip title="Preview Details">
                    <Button
                      type="primary"
                      icon={<EyeOutlined />}
                      onClick={() => handlePreview(record)}
                    />
                  </Tooltip>
                </Space>
              ),
            },
          ]}
        />
      </Card>

      {/* Preview Modal */}
      <Modal
        title="Interview Details"
        open={isPreviewModalOpen}
        onCancel={() => setIsPreviewModalOpen(false)}
        footer={[
          <Button key="close" onClick={() => setIsPreviewModalOpen(false)}>
            Close
          </Button>
        ]}
        width={800}
      >
        {previewLoading ? (
          <div style={{ padding: "20px 0" }}>
            <Skeleton active paragraph={{ rows: 10 }} />
          </div>
        ) : selectedUser ? (
          <div style={{ marginBottom: "16px" }}>
            <Card title="Personal Information" style={{ marginBottom: "16px" }}>
              <Descriptions bordered column={2}>
                <Descriptions.Item label="Name">{selectedUser?.userDetails?.name || "N/A"}</Descriptions.Item>
                <Descriptions.Item label="Email">{selectedUser?.userDetails?.email || "N/A"}</Descriptions.Item>
                <Descriptions.Item label="Phone">{selectedUser?.userDetails?.phone || "N/A"}</Descriptions.Item>
              </Descriptions>
            </Card>

            <Card title="Interview Details" style={{ marginBottom: "16px" }}>
              <Descriptions bordered column={2}>
                <Descriptions.Item label="Interview Date">
                  {formatDate(selectedUser?.interview?.interviewTime)}
                </Descriptions.Item>
                <Descriptions.Item label="Status">
                  {renderStatusTag(selectedUser?.interview?.status)}
                </Descriptions.Item>
                <Descriptions.Item label="Score">
                  <Text strong>{selectedUser?.interview?.score || "N/A"}</Text>/10
                </Descriptions.Item>
                <Descriptions.Item label="Communication Score">
                  <Text strong>{selectedUser?.interview?.communicationScore || "N/A"}</Text>/10
                </Descriptions.Item>
                <Descriptions.Item label="Attempted" span={1}>
                  {selectedUser?.attempted ? 
                    <Tag color="green" icon={<CheckCircleOutlined />}>Yes</Tag> : 
                    <Tag color="red" icon={<CloseCircleOutlined />}>No</Tag>
                  }
                </Descriptions.Item>
                <Descriptions.Item label="Processed" span={1}>
                  {selectedUser?.processed ? 
                    <Tag color="green" icon={<CheckCircleOutlined />}>Yes</Tag> : 
                    <Tag color="red" icon={<CloseCircleOutlined />}>No</Tag>
                  }
                </Descriptions.Item>
              </Descriptions>
            </Card>

            {selectedUser?.interview?.feedback && (
              <Card title="Feedback" style={{ marginBottom: "16px" }}>
                <Text>{selectedUser.interview.feedback}</Text>
              </Card>
            )}

            {selectedUser?.interview?.improvement && (
              <Card title="Areas for Improvement">
                <Text>{selectedUser.interview.improvement}</Text>
              </Card>
            )}
          </div>
        ) : (
          <Empty description="No interview details found" />
        )}
      </Modal>

      {/* Add/Edit User Drawer */}
      <Drawer
        title={currentUser ? "Edit User" : "Add User"}
        open={isDrawerOpen}
        onClose={() => {
          setIsDrawerOpen(false);
          setCurrentUser(null);
          form.resetFields();
        }}
        width={400}
      >
        <Form
          form={form}
          initialValues={
            currentUser ? 
            {
              name: currentUser.userDetails?.name,
              email: currentUser.userDetails?.email,
              phone: currentUser.userDetails?.phone,
              role: currentUser.userDetails?.role
            } : 
            { name: "", email: "", phone: "", role: "" }
          }
          onFinish={handleFormSubmit}
          layout="vertical"
        >
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: "Please input the user name!" }]}
          >
            <Input placeholder="Enter user name" />
          </Form.Item>

          <Form.Item
            name="email"
            label="Email"
            rules={[
              { required: true, message: "Please input the user email!" },
              { type: "email", message: "Please enter a valid email!" },
            ]}
          >
            <Input placeholder="Enter user email" />
          </Form.Item>

          <Form.Item
            name="phone"
            label="Phone"
            rules={[
              { required: true, message: "Please input the phone number!" },
            ]}
          >
            <Input placeholder="Enter phone number" />
          </Form.Item>

          <Button 
            type="primary" 
            htmlType="submit" 
            block 
            loading={formLoading}
          >
            {formLoading ? "Saving..." : "Save"}
          </Button>
        </Form>
      </Drawer>
    </div>
  );
};

export default Interview;
import React, { useEffect, useState } from 'react';
import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";
import AWS from 'aws-sdk';
import { Button, Input, notification, Select, Space } from 'antd';
import {
    PlusOutlined,
    SaveOutlined
  } from "@ant-design/icons"
  import { parse, stringify } from 'flatted';
import Json from './Json';
const { Option } = Select
const JsonEditor = () => {
    const [api, contextHolder] = notification.useNotification();  
    const [jsonFilesContent, setJsonFilesContent] = useState([]);
    const [jsonData, setJsonData] = useState(null);
    const [newJsonData, setNewJsonData] = useState(null);
    const [fileName, setFileName] = useState(null);
    const [showInput, setShowInput] = useState(false);
    const [mode, setMode] = useState('code');
    
    const listAndReadJSONFiles = async (bucketName) => {
        AWS.config.update({
            accessKeyId: 'AKIAYIKMECY6H47Z4AFO',
            secretAccessKey: '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',
            region: 'ap-south-1'
          });
      
          const s3 = new AWS.S3();
          try {
            const listParams = {
              Bucket: bucketName
            };
    
            const listData = await s3.listObjectsV2(listParams).promise();
            const jsonFiles = listData.Contents.filter(file => file.Key.endsWith('.json'));
    
            const filesInfo = jsonFiles.map(file => {
              const url = s3.getSignedUrl('getObject', {
                Bucket: bucketName,
                Key: file.Key,
                // Expires: 60 * 60 // URL expires in 1 hour
              });
              return {
                filename: file.Key,
                url
              };
            });
    
            setJsonFilesContent(filesInfo);
          } catch (error) {
            console.error('Error fetching JSON files:', error);
          }
      };

      const fetchJsonData = async (url) => {
        try {
            // const response = await fetch(`/courses/${courseId}.json`);
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setJsonData(data);
            setNewJsonData(data)
        } catch (error) {
            console.error('Error fetching course data:', error);
            setJsonData(null)
        }
    };

    const handleUpload = async() => {
        // console.log('CALLED',fileName);

        if(!fileName || fileName === ''){
          return api.error({
            message: 'File Name is Required',
            description:'Please Enter a file name',
            showProgress: true,
            pauseOnHover:true,
          });
        }

        if(!fileName.endsWith('.json')){
          return api.error({
            message: 'Incorrect File Name',
            description:'File name should end with .json',
            showProgress: true,
            pauseOnHover:true,
          });
        }

        AWS.config.update({
            accessKeyId: 'AKIAYIKMECY6H47Z4AFO',
            secretAccessKey: '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',
            region: 'ap-south-1'
          });

        const s3 = new AWS.S3();
          try {
            // const jsonData = JSON.stringify(newJsonData);
            const uploadParams = {
              Bucket: 'youvah-cms',
              Key: fileName,
              Body: JSON.stringify(newJsonData),
              ContentType: 'application/json'
            };
    
            const result = await s3.upload(uploadParams).promise();
            api.success({
              message: 'Upload Successful',
              description:
                'Your Json is uploaded to amazon s3 bucket successfully',
              showProgress: true,
              pauseOnHover:true,
            });
            console.log('File uploaded successfully:', result);
            setNewJsonData(null)
            setShowInput(false)
          } catch (error) {
            api.error({
              message: 'Upload Unuccessful',
              description:error?.message,
              showProgress: true,
              pauseOnHover:true,
            });
            console.error('Error uploading JSON file:', error);
          }

    
      };

  //  console.log(jsonFilesContent);
    useEffect(() => {
      listAndReadJSONFiles('youvah-cms');
    }, []);

    console.log(newJsonData);

    const renderHeader = () => {
        return (
          <Space size={16}>
            <Button
          type="default"
          icon={<PlusOutlined />}
          onClick={() => {
            setJsonData( { example: "This is an example JSON",
          })
          setNewJsonData( { example: "This is an example JSON",
          })
          setFileName('')
          setShowInput(true)
        }}
        //   loading={loading}
          style={{ backgroundColor: "#971eff", color: "white" }}
        >
          Add New Json
        </Button>

       {showInput && <Input
        placeholder="Enter text"
        value={fileName}
        onChange={(e)=>{
          setFileName(e.target.value)
        }}
      
      />}

            <p>Choose JSON File</p> <br/>
            <Select placeholder="Choose Json" label="json" onChange={(e)=>{fetchJsonData(e?.split('$')[0])
                setFileName(e?.split('$')[1])
                setShowInput(false)
                // console.log(e)
            }}>
                {jsonFilesContent &&
                  jsonFilesContent.map((slot, key) => {
                    return (
                      <Option key={key} value={slot.url + "$" + slot.filename}>
                        {slot.filename}
                      </Option>
                    )
                  })}
              </Select>

            {JSON?.stringify(newJsonData) !== JSON?.stringify(jsonData) && <Button
                type="primary"
                style={{ width: "200px" }}
                htmlType="button"
                onClick={handleUpload}
              >
                📂 Save
              </Button>}
              <Select placeholder="Choose Editor MOde" value={mode} label="Mode" onChange={(e)=>{
                setMode(e)
            }}>
                  <Option key={1} value={'code'} >Code</Option>  
                  <Option key={2} value={'tree'} >Tree</Option>  
                  <Option key={3} value={'form'} >Form</Option>  
                  <Option key={4} value={'view'} >View</Option>  
                  <Option key={5} value={'text'} >Text</Option>  
               
              </Select>
          </Space>
        )
      }
const handleJsonChange = (updatedJson) => {
    setNewJsonData(updatedJson);
  };
// console.log(newJsonData);
// console.log(JSON?.stringify(newJsonData) !== JSON?.stringify(jsonData) );
    return (
        <div>
            {contextHolder}
            <h2>JSON Editor</h2>
            <div style={{ backgroundColor: "#fff", padding: "20px" }}>
            {renderHeader()}
            </div>
           {newJsonData && <Json jsonData={newJsonData} onChange={handleJsonChange} mode={mode} />}
        </div>
    );
};

export default JsonEditor;